<template>
  <div class="user">
    <page-search
      ref="pageSearchRef"
      :searchFormConfig="searchFormConfig"
      :pageTitle="$t('menu.monitor-ota')"
      @resetBtnClick="handleResetClick"
      @queryBtnClick="handleQueryClick"
    >
    </page-search>
    <page-content
      style="margin-top: 10px"
      ref="pageContentRef"
      v-bind="tableOptionsConfig"
      @viewBtnClick="handleViewData"
      @pageInfoChange="pageInfoChangeAction"
    ></page-content>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import { searchFormConfig } from './config/search'
import { contentTableConfig } from './config/table'
import pageSearch from '@/components/PageSearch/page-search'
import pageContent from '@/components/PageContent/page-content.vue'
const tableOptions = {
  moduleName: 'monitor', // 所属的模块
  pageName: 'monitorOta', // 所属的页面
  contentTableConfig, // 表格列的基础配置
  isShowCreateSlot: false, // 是否显示添加按钮的插槽
  isShowEditBtn: false,
  isShowDeleteBtn: false,
  pageAction: 'monitor/getMonitorOtaList', // 获取表格的Action
  deleteRecordAction: 'monitor/handleDeleteTerminal', // 删除record的Action
  pageCountGetter: 'monitor/pageListCount', // 获取表格记录数据总和getters
  pageListGetter: 'monitor/pageListData' // 获取表格列表数据getters
}
const tableOptionsConfig = computed(() => tableOptions)
const pageSearchRef = ref('')

// 发送网络请求
const pageContentRef = ref()
const handleResetClick = () => {
  pageContentRef.value.getPageData()
}

const handleQueryClick = (queryInfo) => {
  pageContentRef.value.getPageData(queryInfo)
}
const router = useRouter()
const handleViewData = (id) => {
  router.push(`/monitor/ota/detail/${id}`)
}

const pageInfoChangeAction = () => {
  const formData = pageSearchRef.value.getCurrentFieldValues()
  pageContentRef.value.getPageData(formData)
}
</script>
