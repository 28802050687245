export const searchFormConfig = {
  formItems: [
    {
      field: 'extend1',
      type: 'input',
      label: 'monitor.ota-name'
    },
    {
      field: 'extend2',
      type: 'input',
      label: 'file.version-code'
    },
    {
      field: 'updatedBy',
      type: 'input',
      label: 'general.operator'
    },
    {
      field: 'createTime',
      type: 'datepicker',
      label: 'general.operation-date',
      otherOptions: {
        type: 'daterange',
        format: 'YYYY-MM-DD',
        valueFormat: 'YYYY-MM-DD'
      },
      dateFieldName: ['startDateTime', 'endDateTime']
    }
  ]
}
